import styled from "styled-components"
import { animated } from "react-spring"

export const RoadMapContainer = styled.div`
  display: none;

  @media (min-width: 1100px) {
    display: flex;
    justify-content: center;
    padding: 150px 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
`

export const RoadMapSide = styled.div`
  height: 400px;
  width: 93px;
  margin: ${({ side }) => (side === "right" ? "0 0 0 -27px" : "0 -27px 0 0")};
  display: flex;
  flex-direction: ${({ side }) =>
    side === "right" ? "column" : "column-reverse"};
  z-index: 1000;
`

export const StepWrapper = styled.div`
  display: flex;
  flex-direction: ${({ flexDir }) => flexDir};
`
export const StepText = styled.div`
  position: relative;
  padding: 0 10px;
  font-family: "HelveticaNeue Condensed";
  font-weight: bold;
  color: #ffffff;
  font-size: 0.8rem;
  line-height: 1.68;
  letter-spacing: 7px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
`

export const Number = styled.p`
  position: absolute;
  width: 100%;
  bottom: ${({ position }) => position};
  margin: 0;
  left: 25px;
  color: ${({ theme }) =>
    theme === "innerstrength-way" ? "#c15b5a" : "#3883a6"};
  font-family: "HelveticaNeue Condensed";
  font-size: 10rem;
  font-weight: bold;
  line-height: 1;
`

export const Text = styled.div`
  position: absolute;
  bottom: ${({ position }) => position};
  text-align: center;
  min-height: 150px;
  width: 230px;
  overflow-wrap: wrap;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StepContainer = styled.div`
  position: relative;
  height: 400px;
  width: 134px;
  display: flex;
  align-items: center;
  flex-direction: ${({ flexDir }) => flexDir};
`

export const StepCircle = styled(animated.div)`
  display: relative;
  height: 134px;
  width: 134px;
  border-radius: 50%;
  background-color: #343434;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.2s ease-in-out;
  z-index: 1000;
  margin: ${({ margin }) => margin};

  :hover {
    transform: scale(1.05);
  }
`

export const StepCicleBg = styled.img`
  width: 200px;
  position: absolute;
  top: ${({ index }) => (index === "even" ? "none" : "35px")};
  bottom: ${({ index }) => (index === "even" ? "35px" : "none")};
  -webkit-transform: ${({ index }) =>
    index === "even" ? "scaleY(-1)" : "none"};
  transform: ${({ index }) => (index === "even" ? "scaleY(-1)" : "none")};
  z-index: 900;
`
export const Shadow = styled(animated.img)`
  position: absolute;
  z-index: 1;
  left: 0;
  top: ${({ index }) => (index === "even" ? "none" : "65px")};
  bottom: ${({ index }) => (index === "even" ? "0" : "none")};
`

export const StepIcon = styled.img`
  max-height: 50px;
  z-index: 1000;
`

export const DashedLine = styled.div`
  position: absolute;
  left: 50%;
  height: 100%;
  border-right: 1.5px dashed white;
  z-index: 0;
`
