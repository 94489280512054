import styled from "styled-components"

export const TextFullWidthWrapper = styled.section`
  min-height: 100vh;
  display: flex;
  align-items: center;
`

export const TextFullWidthContentContainer = styled.div`
  position: relative;
  width: 100%;
  padding-top: 100px;
  padding-bottom: 40px;
`

export const Heading = styled.h1`
  margin: 0 20px;
  color: #ffffff;
  font-size: 1.5rem;
  line-height: 1.68;
  letter-spacing: 12.5px;
  color: #ffffff;
  text-transform: uppercase;

  @media (min-width: 800px) {
    max-width: 70%;
    margin: 0;
    font-size: 2.5em;
    line-height: 1.5;
    font-weight: bold;
    font-stretch: condensed;
    font-style: normal;
    letter-spacing: 18px;
    text-align: left;
    color: #ffffff;
  }
`

export const Body = styled.div`
  padding: 0 20px;
  & > * {
    color: #ffffff;
  }
  @media (min-width: 800px) {
    padding: 0;
    padding-bottom: 20px;
    width: 100%;
    max-width: 700px;
  }
`
export const BackButton = styled.button`
  display: none;

  @media (min-width: 1000px) {
    display: block;
    position: absolute;
    right: 10%;
    top: 40%;
    height: 70px;
    width: 70px;
    border: 3px solid #ffffff;
    border-radius: 50%;
    background-color: transparent;
    cursor: pointer;

    & > img {
      height: 30px;
      transition: all 0.3s ease-in-out;

      &:hover {
        transform: translateX(3px);
      }
    }
  }
`

export const DialogContent = styled.div`
  background-color: white;
  @media (min-width: 800px) {
    padding: 40px;
  }
`
export const ButtonWrapper = styled.div`
  padding: 0 20px;
  @media (min-width: 800px) {
    padding: 0;
  }
`
