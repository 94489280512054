import React from "react"

import { RoadMapDesktop } from "./RoadMapDesktop"
import { RoadMapMobile } from "./RoadMapMobile"

export const RoadMap = ({ data, theme }) => {
  return (
    <>
      <RoadMapMobile data={data} theme={theme} />
      <RoadMapDesktop data={data} theme={theme} />
    </>
  )
}
