import styled from "styled-components"

export const Input = styled.input`
  height: 100px;
  border: ${props =>
    props.isError ? "4px solid #ff6e6e" : "4px solid #343434"};
  width: 100%;
  box-sizing: border-box;

  @media (min-width: 800px) {
    height: 100px;
  }

  box-sizing: border-box;
  padding-left: 20px;
  font-family: "HelveticaNeue Condensed";
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  line-height: 2;
  letter-spacing: 7px;
  text-align: left;
  color: #343434;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  ::placeholder {
    font-family: "HelveticaNeue Condensed";
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    line-height: 2;
    letter-spacing: 7px;
    text-align: left;
    color: #343434;
  }
`

export const ErrorMessage = styled.div`
  color: #ff6e6e;

  font-size: 0.9rem;
`
