import React, { useState } from "react"
import { useTrail } from "react-spring"
import { Waypoint } from "react-waypoint"

// Assets
import bgCirclePath from "../../../../images/bg-circle-path.svg"
import bgCircleShadow from "../../../../images/bg-circle-shadow.svg"
import bgBegin from "../../../../images/roadmap-begin.svg"
import bgEnd from "../../../../images/roadmap-end.svg"

// Styled components
import {
  RoadMapContainer,
  StepWrapper,
  StepText,
  Number,
  Text,
  StepContainer,
  StepCircle,
  StepCicleBg,
  Shadow,
  StepIcon,
  DashedLine,
  RoadMapSide,
} from "./roadmap-desktop.styles"

// Components
import { PortView } from "../../../slices-styles"

export const RoadMapDesktop = ({ data, theme }) => {
  const [inScreen, setInScreen] = useState(false)

  const trail = useTrail(data.items.slice(0, 6).length, {
    delay: 300,
    to: {
      opacity: inScreen ? 1 : 0,
    },
    from: {
      opacity: inScreen ? 0 : 1,
    },
  })

  return (
    <>
      <Waypoint onEnter={() => setInScreen(true)} />
      <PortView>
        <RoadMapContainer>
          <RoadMapSide side="left">
            <img src={bgBegin} alt="bg-begin" />
          </RoadMapSide>
          {trail &&
            trail.map((animation, i) => {
              const { step_title, step_icon } = data.items[i]
              return (
                <StepWrapper flexDir={!(i % 2) ? "column" : "column-reverse"}>
                  <StepText>
                    <Number position={!(i % 2) ? 0 : "none"} theme={theme}>
                      {i + 1}
                    </Number>
                    <Text position={!(i % 2) ? 0 : "none"}>
                      {step_title && step_title}
                    </Text>
                  </StepText>
                  <StepContainer
                    flexDir={!(i % 2) ? "column" : "column-reverse"}
                  >
                    <StepCircle
                      style={animation}
                      margin={!(i % 2) ? "64px 0 0 0" : "0 0 64px 0"}
                    >
                      <StepIcon src={step_icon.url} alt="step" />
                    </StepCircle>
                    <StepCicleBg
                      index={!(i % 2) ? "odd" : "even"}
                      src={bgCirclePath}
                      alt="bg"
                    />
                    <Shadow
                      style={animation}
                      index={!(i % 2) ? "odd" : "even"}
                      src={bgCircleShadow}
                      alt="step"
                    />
                    <DashedLine />
                  </StepContainer>
                </StepWrapper>
              )
            })}
          <RoadMapSide side="right">
            <img src={bgEnd} alt="bg-end" />
          </RoadMapSide>
        </RoadMapContainer>
      </PortView>
    </>
  )
}
