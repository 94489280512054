import React, { useState } from "react"
import i18n from "../../../../config/i18n"
import YouTube from "react-youtube"

import * as yup from "yup"
import { useSpring } from "react-spring"

import { LocaleContext } from "../../../components/Layout"
import cross from "../../../images/close-icon-black.svg"

import { Formik, Form } from "formik"
import { FormField } from "../../../components/ContactForm/FormField"
import { Button } from "../../../components/Button"
import { LocalizedLink } from "../../../components/Primitives/LocalizedLink"
import { Loader } from "../../../components/Loader"

import {
  FormContainer,
  FormWrapper,
  ContactFormHeader,
  ContactFormHeading,
  LoaderContainer,
  VideoWrapper,
  CloseButtonWrapper,
  CloseButton,
  FormColumn,
  FormColumnLabel,
  PrivacyPolicy,
} from "./intakeform-styles"

export const IntakeForm = ({ close, form }) => {
  const lang = React.useContext(LocaleContext)
  const { locale } = lang
  const staticText = i18n[locale].intakeForm

  const [loading, setLoading] = useState(false)
  const [confirmation, setConfirmation] = useState(false)

  const validationSchema = yup.object({
    name: yup.string().required(staticText.errorName),
    email: yup
      .string()
      .required(staticText.errorEmailReq)
      .email(staticText.error),
    phone: yup
      .string()
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        staticText.errorPhone
      ),
  })
  console.log(form)
  const handleSubmit = (values, { resetForm }) => {
    setLoading(true)
    return fetch(
      `https://api.formik.com/submit/innerstrength-amsterdam/intake-${form}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      }
    )
      .then(() => {
        resetForm({})
        setTimeout(() => setLoading(false), 2000)
        setTimeout(() => setConfirmation(true), 2000)
      })
      .catch(err => {
        alert("Error, please try again later")
        console.log(err)
      })
  }

  const formAnimation = useSpring({
    opacity: loading ? 0 : 1,
  })

  const loaderAnimation = useSpring({
    opacity: loading ? 1 : 0,
  })

  const headerAnimation = useSpring({
    opacity: confirmation ? 0 : 1,
  })

  const videoConfig = {
    height: "600px",
    width: "100%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  }
  return (
    <FormContainer>
      <ContactFormHeader>
        <ContactFormHeading
          style={headerAnimation}
          dangerouslySetInnerHTML={{
            __html: staticText && staticText.formHeading,
          }}
        />

        <CloseButton onClick={close}>
          <img src={cross} alt="close menu" />
        </CloseButton>
      </ContactFormHeader>
      {!confirmation ? (
        <Formik
          initialValues={{
            name: "",
            email: "",
            phone: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <FormWrapper style={formAnimation}>
                <FormColumn>
                  <FormColumnLabel>{staticText.formText}</FormColumnLabel>
                  <FormField
                    name="name"
                    type="text"
                    placeholder={staticText.placeholderName}
                  />
                  <FormField
                    name="email"
                    type="text"
                    placeholder={staticText.placeholderEmail}
                  />
                  <FormField
                    name="phone"
                    type="tel"
                    placeholder={staticText.placeholderPhone}
                  />
                  <PrivacyPolicy>
                    <input
                      name="accept-privacy-policy"
                      type="checkbox"
                      id="accept-privacy-policy"
                      required
                    />
                    <span>
                      {staticText.privacyPolicyText}{" "}
                      <LocalizedLink to="/privacy-policy">
                        privacy policy
                      </LocalizedLink>
                    </span>
                  </PrivacyPolicy>
                  <Button
                    noPadding
                    type="submit"
                    disabled={isSubmitting}
                    text={staticText.buttonText}
                  />
                </FormColumn>
              </FormWrapper>
            </Form>
          )}
        </Formik>
      ) : (
        <VideoWrapper>
          <YouTube
            opts={videoConfig}
            videoId={staticText.confirmationVideoId}
          />
        </VideoWrapper>
      )}
      <LoaderContainer style={loaderAnimation}>
        <Loader />
        <p>SUBMITTING...</p>
      </LoaderContainer>
    </FormContainer>
  )
}
