import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import { SEO } from "../components/Seo"
import { LocaleContext } from "../components/Layout/Layout"
import { useTheme } from "../context/theme/ThemeContext"

import { ServicePageWrapper } from "./servicepage-styles"
import { TextFullWidth } from "../slices/service-page"
import { TextAndSlider } from "../slices/service-page"
import { Tables } from "../slices/service-page/Tables"
import { RoadMap } from "../slices/service-page/Roadmap"

const ServicePage = ({
  data: { prismicServicePage },
  location,
  pageContext: { locale },
}) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]

  const {
    uid,
    data: { meta_title, meta_description, body },
  } = prismicServicePage

  const themeState = useTheme()

  useEffect(() => {
    const theme = uid === "innerstrength-personal-training" ? "blue" : "red"

    themeState.setTheme(theme)
  }, [])

  const form =
    uid === "innerstrength-personal-training"
      ? "personal-coaching"
      : "innerstrength-way"

  const slices = body.map((slice, index) => {
    switch (slice.slice_type) {
      // These are the API IDs of the slices
      case "text_full_width":
        return <TextFullWidth key={index} data={slice} form={form} />
      // case "text_and_slider":
      //   return <TextAndSlider key={index} data={slice} form={form} />
      case "tables":
        return <Tables key={index} data={slice} form={form} />
      case "roadmap":
        return <RoadMap key={index} data={slice} theme={form} />
      default:
        return null
    }
  })

  return (
    <ServicePageWrapper>
      <SEO
        title={`${meta_title} | ${i18n.defaultTitleAlt}`}
        pathname={location.pathname}
        locale={locale}
        desc={meta_description}
        node={prismicServicePage}
      />
      {slices && slices}
    </ServicePageWrapper>
  )
}

export default ServicePage

export const pageQuery = graphql`
  query ServicePageBySlug($uid: String!, $locale: String!) {
    prismicServicePage(uid: { eq: $uid }, lang: { eq: $locale }) {
      uid
      data {
        meta_title
        meta_description
        body {
          ... on PrismicServicePageBodyTextFullWidth {
            slice_type
            primary {
              body1 {
                html
              }
              heading
              button_text
            }
          }
          ... on PrismicServicePageBodyTextAndSlider {
            slice_type
            primary {
              text_heading {
                html
              }
              text_body {
                html
              }
            }
            items {
              slide_number
              slide_text {
                html
              }
            }
          }
          ... on PrismicServicePageBodyTables {
            slice_type
            primary {
              heading
            }
            items {
              table_heading {
                html
              }
              table_body {
                html
              }
              table_button_text
              table_button_link
            }
          }
          ... on PrismicServicePageBodyRoadmap {
            slice_type
            items {
              step_title
              step_icon {
                url
              }
            }
          }
        }
      }
    }
  }
`
