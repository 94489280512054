import styled from "styled-components"
import { animated } from "react-spring"

export const FormContainer = styled.div`
  position: relative;
`

export const FormWrapper = styled(animated.div)`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 20px;

  @media (min-width: 800px) {
    width: 600px;
    flex-direction: row;
  }
`

export const ContactFormHeader = styled(animated.div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ContactFormHeading = styled(animated.div)`
  font-family: "HelveticaNeue Condensed";
  font-size: 1.3rem;
  font-weight: bold;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 12.5px;
  text-align: left;
  padding-left: 20px;

  & > h3 {
    margin: 0;
  }

  @media (min-width: 800px) {
    font-size: 2rem;
  }
`

export const CloseButton = styled.button`
  height: 50px;
  width: 50px;
  margin: 15px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  & > img {
    width: 100%;
  }
`
export const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;

  @media (min-width: 800px) {
    max-width: 600px;
  }

  & * + * {
    margin-top: 20px;
  }
`
export const FormColumnLabel = styled.div`
  font-family: "HelveticaNeue Condensed";
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  line-height: 2;
  letter-spacing: 5px;
  text-align: left;
  color: #343434;
`

export const PrivacyPolicy = styled.div`
  display: flex;

  & > input[type="checkbox"] {
    appearance: none;
    position: relative;
    cursor: pointer;

    && {
      padding: 0 8px;
    }

    width: 18px;
    height: 18px;
    border: 1px solid #343434;

    &:checked {
      background: #16cc29;
      border: none;
    }
    &:checked:after {
      content: "";
      position: absolute;
      width: 4px;
      height: 10px;
      transform: rotate(45deg);
      box-sizing: content-box;
      border-right: 2px solid white;
      border-bottom: 2px solid white;
      bottom: 4px;
      left: 6px;
    }
  }

  & > span {
    max-width: 250px;
    margin: 0 0 0 20px;
    font-family: "HelveticaNeue Condensed";
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: 7px;
    text-align: left;
    color: #343434;
  }
`
export const LoaderContainer = styled(animated.div)`
  position: absolute;
  top: 60px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: none;

  & > p {
    font-family: "HelveticaNeue Condensed";
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: 5px;
    color: #343434;
  }
`
export const VideoWrapper = styled.div`
  width: 100%;
  height: 600px;

  @media (min-width: 800px) {
    width: 100vw;
    max-width: 1000px;
  }
`
export const CloseButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
`
