import React, { useState } from "react"

import Swiper from "react-id-swiper"
import "swiper/css/swiper.css"

import check from "../../../images/checkmark-white.png"
import { DialogOverlay } from "@reach/dialog"
import { DialogContent } from "../TextFullWidth/textfullwidth-styles"

import {
  TableSection,
  TableWrapper,
  TableHeading,
  TableBody,
  TableButton,
  MobileTablesContainer,
  DesktopTablesContainer,
  Heading,
} from "./tables-styles"
import { PortView } from "../../slices-styles"
import { IntakeForm } from "../IntakeForm"

export const Tables = ({ data, form }) => {
  const { items, primary } = data
  const [showDialog, setShowDialog] = useState(false)

  const open = () => setShowDialog(true)
  const close = () => setShowDialog(false)

  return (
    <TableSection>
      <PortView>
        <Heading>{primary.heading}</Heading>
      </PortView>

      <MobileTables items={items} open={open} />
      <DesktopTables items={items} open={open} />
      <DialogOverlay
        style={{
          background: "hsla(0, 0, 0, 0.9)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: "1000",
        }}
        isOpen={showDialog}
      >
        <DialogContent
          style={{
            zIndex: "2000",
          }}
        >
          <IntakeForm showDialog={showDialog} form={form} close={close} />
        </DialogContent>
      </DialogOverlay>
    </TableSection>
  )
}

const MobileTables = ({ items, open }) => {
  const params = {
    spaceBetween: 50,
    slidesPerView: 1.3,
    loop: true,
  }

  return (
    <MobileTablesContainer>
      <Swiper {...params}>
        {items &&
          items.map((slide, index) => {
            return (
              <TableWrapper key={index}>
                <TableHeading
                  dangerouslySetInnerHTML={{
                    __html: slide.table_heading && slide.table_heading.html,
                  }}
                />
                <TableBody
                  listImg={check}
                  dangerouslySetInnerHTML={{
                    __html: slide.table_body && slide.table_body.html,
                  }}
                />
                <TableButton onClick={open}>
                  {slide.table_button_text && slide.table_button_text}
                </TableButton>
              </TableWrapper>
            )
          })}
      </Swiper>
    </MobileTablesContainer>
  )
}

const DesktopTables = ({ items, open }) => {
  return (
    <PortView>
      <DesktopTablesContainer>
        {items &&
          items.map((slide, index) => {
            return (
              <TableWrapper key={index}>
                <TableHeading
                  dangerouslySetInnerHTML={{
                    __html: slide.table_heading && slide.table_heading.html,
                  }}
                />
                <TableBody
                  listImg={check}
                  dangerouslySetInnerHTML={{
                    __html: slide.table_body && slide.table_body.html,
                  }}
                />
                <TableButton onClick={open}>
                  {slide.table_button_text && slide.table_button_text}
                </TableButton>
              </TableWrapper>
            )
          })}
      </DesktopTablesContainer>
    </PortView>
  )
}

export default Tables
