import React, { useState } from "react"
import { DialogOverlay } from "@reach/dialog"

import {
  TextFullWidthWrapper,
  TextFullWidthContentContainer,
  Heading,
  Body,
  BackButton,
  DialogContent,
  ButtonWrapper,
} from "./textfullwidth-styles"

import { PortView } from "../../slices-styles"
import { Button } from "../../../components/Button"
import arrow from "../../../images/arrow-icon-white.svg"
import { LocalizedLink } from "../../../components/Primitives/LocalizedLink"
import { IntakeForm } from "../IntakeForm"

export const TextFullWidth = ({ data, form }) => {
  const { heading, body1, button_text } = data.primary
  const [showDialog, setShowDialog] = useState(false)

  const open = () => setShowDialog(true)
  const close = () => setShowDialog(false)

  return (
    <>
      <TextFullWidthWrapper>
        <PortView>
          <TextFullWidthContentContainer>
            <Heading>{heading}</Heading>
            <Body
              dangerouslySetInnerHTML={{
                __html: body1 && body1.html,
              }}
            />
            <ButtonWrapper>
              <Button
                noPadding
                action={() => open()}
                text={button_text && button_text}
              />
            </ButtonWrapper>
          </TextFullWidthContentContainer>
        </PortView>
      </TextFullWidthWrapper>
      <DialogOverlay
        style={{
          background: "hsla(0, 0, 0, 0.9)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: "1000",
        }}
        isOpen={showDialog}
      >
        <DialogContent
          style={{
            zIndex: "2000",
          }}
        >
          <IntakeForm showDialog={showDialog} form={form} close={close} />
        </DialogContent>
      </DialogOverlay>
      <LocalizedLink to={`/`}>
        <BackButton>
          <img src={arrow} alt="back-to-home" />
        </BackButton>
      </LocalizedLink>
    </>
  )
}

export default TextFullWidth
