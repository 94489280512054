import styled from "styled-components"
import { animated } from "react-spring"

export const RoadMapWrapper = styled.div`
  overflow: hidden;
  padding-bottom: 120px;
`

export const RoadMapContainer = styled.div`
  max-width: 400px;
  margin: 0 auto;

  @media (min-width: 1100px) {
    display: none;
  }
`

export const StepWrapper = styled.div`
  position: relative;
  min-height: 250px;
  border-bottom: 1.5px dashed white;
`

export const StepText = styled.div`
  position: relative;
  margin-left: 20px;
`

export const Number = styled.p`
  position: absolute;
  top: 20px;
  margin: 0;
  width: 100%;
  height: 200px;
  color: ${({ theme }) =>
    theme === "innerstrength-way" ? "#c15b5a" : "#3883a6"};
  font-family: "HelveticaNeue Condensed";
  font-size: 10rem;
  font-weight: bold;
  line-height: 1;
`

export const Text = styled.div`
  position: absolute;
  top: 40px;
  margin-left: 40px;
  width: 250px;
  height: 200px;
  overflow-wrap: wrap;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-family: "HelveticaNeue Condensed";
  font-weight: bold;
  color: #ffffff;
  font-size: 1rem;
  line-height: 1.68;
  letter-spacing: 7px;
  text-transform: uppercase;
`

export const CircleWrapper = styled.div`
  position: absolute;
  bottom: -67px;
  width: 100%;
  display: flex;
  justify-content: center;
`

export const StepCircle = styled.div`
  position: relative;
  height: 134px;
  width: 134px;
  border-radius: 50%;
  background-color: #343434;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.2s ease-in-out;
  z-index: 1001;
`

export const StepIcon = styled.img`
  max-height: 50px;
  z-index: 1000;
`
export const Shadow = styled(animated.img)`
  position: absolute;
  z-index: -10;
  top: 0;
  left: 0;
`

export const BgPath = styled.div`
  display: ${({ i }) => (i != 0 ? "block" : "none")};
  position: absolute;
  top: -6px;
  left: ${({ i }) => (i % 2 === 0 ? "none" : "-20px")};
  right: ${({ i }) => (i % 2 === 0 ? "-20px" : "none")};
  height: 105%;
  width: 270px;
  border: 20px solid #343434;
  border-top-left-radius: 50%; /* 100px of height + 10px of border */
  border-bottom-left-radius: 50%;
  box-sizing: border-box;
  border-right: 0;
  transform: ${({ i }) => (i % 2 === 0 ? "scaleX(-1)" : "none")};
  margin: 0;
`
