import React from "react"

import bgCircleShadow from "../../../../images/bg-circle-shadow.svg"

// Styled components
import {
  RoadMapWrapper,
  RoadMapContainer,
  StepWrapper,
  StepCircle,
  StepIcon,
  Shadow,
  StepText,
  Number,
  Text,
  CircleWrapper,
  BgPath,
} from "./roadmap-mobile.styles"

export const RoadMapMobile = ({ data, theme }) => {
  return (
    <RoadMapWrapper>
      <RoadMapContainer>
        {data &&
          data.items.map((item, i) => {
            const { step_title, step_icon } = item
            return (
              <StepWrapper>
                <StepText>
                  <Number theme={theme}>{i + 1}</Number>
                  <Text>{step_title && step_title}</Text>
                </StepText>
                <CircleWrapper>
                  <StepCircle>
                    <StepIcon src={step_icon.url} alt="step" />
                    <Shadow src={bgCircleShadow} />
                  </StepCircle>
                </CircleWrapper>
                <BgPath i={i} />
              </StepWrapper>
            )
          })}
      </RoadMapContainer>
    </RoadMapWrapper>
  )
}
