import React from "react"
import { useField } from "formik"

import { Input, ErrorMessage } from "./formfield-styles"

export const FormField = ({ label, ...props }) => {
  const [field, meta, helpers] = useField(props)
  const isError = meta.touched && meta.error
  return (
    <>
      <Input {...field} {...props} isError={isError} />
      {meta.touched && meta.error ? (
        <ErrorMessage>{meta.error}</ErrorMessage>
      ) : null}
    </>
  )
}

export default FormField
