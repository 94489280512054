import styled from "styled-components"
import { backgroundColor } from "../../../context/theme/theme"

export const TextAndSliderWrapper = styled.section`
  padding: 40px 0;
  background-color: #343434;

  & > .swiper-pagination-bullet-active,
  .swiper-pagination-bullet-active-main {
    background-color: ${backgroundColor};
  }
  & > .swiper-pagination-bullet,
  .swiper-pagination-bullet-active-next,
  .swiper-pagination-bullet-active-next-next,
  .swiper-pagination-bullet-active-prev,
  .swiper-pagination-bullet-active-prev-prev {
    background-color: white !important;
  }
`
export const FlexContainer = styled.div`
  @media (min-width: 800px) {
    display: flex;
    padding: 100px 0;
  }
`

export const TextContainer = styled.div`
  @media (min-width: 800px) {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`

export const SlideContainer = styled.div`
  @media (min-width: 800px) {
    display: flex;
    align-items: center;
    width: 400px;
  }
`

export const Heading = styled.div`
  & > h2 {
    margin: 0 20px;
    color: #ffffff;
    font-size: 1.5rem;
    line-height: 1.68;
    letter-spacing: 12.5px;
    color: #ffffff;
    text-transform: uppercase;

    @media (min-width: 800px) {
      max-width: 70%;
      margin: 0;
      font-size: 2.5em;
      line-height: 1.5;
      font-weight: bold;
      font-stretch: condensed;
      font-style: normal;
      letter-spacing: 18px;
      text-align: left;
      color: #ffffff;
    }

    & > .highlight {
      color: ${backgroundColor};
    }
  }
`

export const Body = styled.div`
  padding: 0 20px;
  & > * {
    color: #ffffff;
  }
  @media (min-width: 800px) {
    padding: 0;
    padding-bottom: 20px;
    max-width: 50%;
  }
`
export const Slide = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  @media (min-width: 800px) {
    width: 400px;
  }
`

export const Number = styled.p`
  margin: 0;
  color: ${backgroundColor};
  font-family: "HelveticaNeue Condensed";
  font-size: 25rem;
  font-weight: bold;
  line-height: 1;
`
export const SlideBody = styled.div`
  position: absolute;
  height: 100%;
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > * {
    color: #ffffff;
    font-family: "HelveticaNeue Condensed";
    font-size: 1.5rem;
    line-height: 1.68;
    font-weight: bold;
    letter-spacing: 12.5px;
    color: #ffffff;

    @media (min-width: 800px) {
      font-size: 2.1rem;
    }
  }
  @media (min-width: 800px) {
    max-width: 210px;
  }
`
