import styled from "styled-components"

export const TableSection = styled.section`
  padding: 0;
  @media (min-width: 800px) {
    padding: 100px 0;
  }
`

export const TableWrapper = styled.div`
  position: relative;
  min-height: 300px;

  border: 3px solid #ffffff;

  @media (min-width: 800px) {
    width: 30%;
  }
`
export const TableHeading = styled.div`
  border-bottom: 3px solid #ffffff;
  height: 130px;
  display: flex;
  align-items: center;
  text-transform: uppercase;

  & > h3 {
    margin-left: 20px;
    font-family: "HelveticaNeue Condensed";
    font-size: 1.2rem;
    font-weight: bold;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 12.5px;
    text-align: left;
    color: #ffffff;
    overflow-wrap: break-word;
  }
`
export const TableBody = styled.div`
  & > ul {
    margin-bottom: 100px;

    & > li:before {
      content: "";
      width: 20px;
      height: 20px;
      position: absolute;
      background-image: url(${props => (props.listImg ? props.listImg : null)});
      background-size: cover;
      background-position: center;
      transform: translateX(-40px);
    }

    & li {
      margin: 40px 20px;
      font-family: "HelveticaNeue Condensed";
      font-size: 0.7rem;
      font-weight: bold;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 5px;
      text-align: left;
      color: #ffffff;
      overflow-wrap: break-word;

      @media (min-width: 800px) {
        margin: 40px 20px;
      }
    }
  }
`

export const TableButton = styled.button`
  position: absolute;
  bottom: 0;
  height: 60px;
  width: 100%;
  background-color: #343434;
  border: none;
  cursor: pointer;
  font-family: "HelveticaNeue";
  font-size: 0.9rem;
  font-weight: bold;
  line-height: 1.19;
  letter-spacing: 4.8px;

  color: #ffffff;
`
export const MobileTablesContainer = styled.div`
  @media (min-width: 1100px) {
    display: none;
  }
`
export const DesktopTablesContainer = styled.div`
  display: none;
  height: 100%;

  @media (min-width: 1100px) {
    display: flex;
    justify-content: space-between;
  }
`

export const Heading = styled.h1`
  margin: 0 20px;
  margin-bottom: 20px;
  color: #ffffff;
  font-size: 1.5rem;
  line-height: 1.68;
  letter-spacing: 12.5px;
  color: #ffffff;
  text-transform: uppercase;

  @media (min-width: 800px) {
    max-width: 70%;
    margin: 0;
    margin-bottom: 40px;
    font-size: 2.5em;
    line-height: 1.5;
    font-weight: bold;
    font-stretch: condensed;
    font-style: normal;
    letter-spacing: 18px;
    text-align: left;
    color: #ffffff;
  }
`
